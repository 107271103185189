// import '../styles/index.css';
// import '../styles/calendar.css';
// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
// import { wrapper } from '../lib/store';
// import ErrorBoundary from '../lib/ErrorBoundary';
// import { Provider } from 'react-redux';
// import { StringsProvider } from '../lib/StringsContext';
// import { VenueProvider } from '../lib/VenueContext';
// import Hotjar from '@hotjar/browser';

// const siteId = 3723941;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

// function MyApp({ Component, ...rest }) {
//   const { store, props } = wrapper.useWrappedStore(rest);

//   return (
//     <ErrorBoundary>
//       <Provider store={store}>
//         <VenueProvider value={props?.pageProps?.venue}>
//           <StringsProvider value={props?.pageProps?.strings}>
//             <Component {...props.pageProps} />
//           </StringsProvider>
//         </VenueProvider>
//       </Provider>
//     </ErrorBoundary>
//   );
// }

// export default MyApp;
import { useEffect, useState } from 'react';
import '../styles/index.css';
import '../styles/calendar.css';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { wrapper } from '../lib/store';
import ErrorBoundary from '../lib/ErrorBoundary';
import { Provider } from 'react-redux';
import { StringsProvider } from '../lib/StringsContext';
import { VenueProvider } from '../lib/VenueContext';
import Hotjar from '@hotjar/browser';
import { hotjarSiteId, hotjarVersion } from '../lib/utils/hotjar';
import { useRouter } from 'next/router';
import locales from '../locales';
import { setupGTM } from '../lib/utils/gtmTag';

function MyApp({ Component, ...rest }) {
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore(rest);
  const [isNavigationComplete, setIsNavigationComplete] = useState(false);
  const [gtmInitialized, setGtmInitialized] = useState(false)

  const venueId = router?.query?.venueId
  const countryCode = venueId?.toString()?.split('-')[0]?.toUpperCase()

  
  useEffect(() => {
    if (hotjarSiteId) {
      Hotjar.init(hotjarSiteId, hotjarVersion);
    }
  }, []);

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (window) {
        const currentLocale = window.location?.pathname?.split('/')[1];
        if (!locales.includes(currentLocale)) {
          try {
            const newLocale = locales.includes(window.navigator.language.substring(0, 2))
              ? window.navigator.language.substring(0, 2)
              : 'en';
            await router.replace(`/${newLocale}${router.asPath}`);
          } catch (error) {
            console.error('Navigation error:', error);
          }
        }
      }
      setIsNavigationComplete(true);
    };
    checkAndRedirect();
  }, [router.asPath]);

  useEffect(() => {
    if (!gtmInitialized && countryCode) {
      setupGTM(countryCode);
      setGtmInitialized(true);
    }
  }, [gtmInitialized, countryCode]);

  // const defaultLocale = 'en'

  // useEffect(() => {
  //   const checkAndRedirect = async () => {
  //     if (typeof window !== 'undefined') {
  //       let currentPath = window.location.pathname;
  //       const currentLocale = currentPath.split('/')[1];
  //       // Check if the current locale is in the URL and supported
  //       if (!locales.includes(currentLocale)) {
  //         const browserLocale = window.navigator.language.substring(0, 2);
  //         let newLocale = locales.includes(browserLocale) ? browserLocale : router.defaultLocale;

  //         // Add default locale prefix if it's missing
  //         if (newLocale === router.defaultLocale && !currentPath.startsWith(`/${router.defaultLocale}/`)) {
  //           currentPath = `/${router.defaultLocale}${currentPath}`;
  //         } else if (newLocale !== currentLocale) {
  //           currentPath = `/${newLocale}${currentPath}`;
  //         }

  //         await router.replace(currentPath, undefined, { shallow: true });
  //       }
  //     }
  //     setIsNavigationComplete(true);
  //   };
  //   checkAndRedirect();
  // }, [router.asPath, router.defaultLocale, router.locales]);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <VenueProvider value={props?.pageProps?.venue}>
          <StringsProvider value={props?.pageProps?.strings}>
            {isNavigationComplete && <Component {...props.pageProps} />}
          </StringsProvider>
        </VenueProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default MyApp;
